<script>
  import { onMount } from 'svelte';
  import { createScene } from "./stage";
  let el;
  onMount(() => {
      createScene(el)
  });

  let titleVisible = true;
  let titleInDom = true;
  
  let cameraAlertVisible = false;
  let cameraAlertInDom = false;

  const releaseCameraAfterSeconds = 130 // 2 mins 10 seconds

  // schedule fade out of title
  setTimeout(() => { titleVisible = false }, 6000)
  // schedule removal of title, allowing clicking to move camera
  setTimeout(() => { titleInDom = false }, 1000 * releaseCameraAfterSeconds)

  // schedule addition of camera release text
  setTimeout(() => { cameraAlertInDom = true }, 1000 * releaseCameraAfterSeconds)
  // schedule fade in of camera alert
  setTimeout(() => { cameraAlertVisible = true }, 1000 * (releaseCameraAfterSeconds + 1))
  // schedule fade out of camera alert
  setTimeout(() => { cameraAlertVisible = false }, 1000 * (releaseCameraAfterSeconds + 7))
  // schedule removal of camera release text
  setTimeout(() => { cameraAlertInDom = false }, 1000 * (releaseCameraAfterSeconds + 8))
</script>

{#if titleInDom}
<div id="title-container" class="{titleVisible ? 'visibility' : 'invisibility'}">
  <p></p>
  <p class="title">Friendred</p>
  <hr>
	<div class="subtitle">
    <span>Currently Offline</span>
    <!-- <span>Moving Photon </span><span class="show-name">: Remote</span> -->
  </div>
</div>
{/if}
{#if cameraAlertInDom}
<div id="camera-alert" class="{cameraAlertVisible ? 'visibility' : 'invisibility'}">
  <span>Camera Released.<br><br>Click, Drag and Scroll<br>to Explore.</span>
</div>
{/if}
<canvas id="canvas" bind:this={el}></canvas>

<style>
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

canvas {
  width: 100% !important;
  height: 100%;
}

.subtitle {
  padding: 1em 1em 0 1em;
}

hr {
  width: 100vw;
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff24;
  margin-top: 1em;
}

#title-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;
  
  color: white;
  letter-spacing: 0.5em;
  font-variant-caps: all-petite-caps;
  font-weight: 100;
  font-size: 2em;
  padding-top: 8em;
  transition: opacity 2s;
}

#camera-alert {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  
  color: white;
  letter-spacing: 0.5em;
  font-variant-caps: all-petite-caps;
  font-weight: 100;
  font-size: 2em;
  padding-top: 8em;
  padding-left: 1em;
  padding-right: 1em;
  transition: opacity 2s;
}

.show-name {
  color: #ffffff55;
}

.invisibility {
  opacity: 0;
}

.visibility {
  opacity: 1;
}
</style>