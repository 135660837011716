<script>
  import Scene from "./pages/Scene.svelte";
</script>

<div>
    <Scene /> 
</div>

<style>
  * {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
</style>