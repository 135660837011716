import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { cameraShots } from "./cameraShots"

let moveCameraYWithRollDataConfigs = [
	false,
	false,
	false,
	false,
	false,
	false,
]

// could be swapped for participant names for a personal touch
let actuatorNames = [
	'Ala',
	'2',
	'3',
	'4',
	'5',
	'6',
]

const lerpSmoothness = 0.25

let targetForConsoleOutput = null;

const consoleContainer = document.createElement('pre');
consoleContainer.id = 'console-container'

consoleContainer.style.whiteSpace = 'pre';
consoleContainer.style.position = 'fixed';
consoleContainer.style.right = '0';
consoleContainer.style.marginTop = '2em';
consoleContainer.style.width = '6em';
consoleContainer.style.transform = 'rotate(90deg)';
consoleContainer.style.color = '#ffffff55';
consoleContainer.style.boxSizing = 'border-box';
consoleContainer.style.fontSize = '1.5em';
consoleContainer.style.fontFamily = 'monospace';
consoleContainer.style.display = 'none'

consoleContainer.innerHTML = null
document.body.appendChild(consoleContainer)

const showConsoleContainer = () => {
	consoleContainer.style.display = 'block'
}

const hideConsoleContainer = () => {
	consoleContainer.style.display = 'none'
}

let dataControlsLightPosition = false
let lightPositionNeedsReset = [
	false,
	false,
	false,
	false,
	false,
	false,
]

const resetLightPosition = (actuatorNumber) => {
	const originalPositions = [
		new THREE.Vector3(7.5 * 2, 8, -2.9 * 2),
		new THREE.Vector3(6.3 * 2, 8, 3.6 * 2),
		new THREE.Vector3(-0.3 * 2, 8, 6.4 * 2),
		new THREE.Vector3(-7.2 * 2, 8, 1.6 * 2),
		new THREE.Vector3(-4.9 * 2, 8, -4.3 * 2),
		new THREE.Vector3(1.4 * 2, 8, -6.7 * 2)
	]

	points[actuatorNumber] = originalPositions[actuatorNumber]

	lightPositionNeedsReset[actuatorNumber] = false
}
 
const handlePitch = (pitch, actuatorNumber) => {
	if (dataControlsLightPosition) {
		const positiveValue = pitch < 0 ? pitch * -1 : pitch
		const boundedValue = positiveValue * 20 // transform into canvas-ish size range
		points[actuatorNumber].y = boundedValue
		lightPositionNeedsReset[actuatorNumber] = true
		return
	}
	if (lightPositionNeedsReset[actuatorNumber]) {
		resetLightPosition()
	}
	// transform (-1 => 1) range into normalised values for colors (0 => 1)
	const boundedValue = pitch * 0.5 + 0.5;
	colors[actuatorNumber].setRGB(boundedValue, colors[actuatorNumber].g, boundedValue);
};

const handleRoll = (roll, actuatorNumber) => {
	if (dataControlsLightPosition) {
		const defaultX = [
			7.5 * 2,
			6.3 * 2,
			-0.3 * 2,
			-7.2 * 2,
			-4.9 * 2,
			1.4 * 2
		]
		const boundedValue = roll * 35 // transform into canvas-ish size range
		points[actuatorNumber].x = defaultX[actuatorNumber] + boundedValue
		lightPositionNeedsReset[actuatorNumber] = true
		return
	}
	if (lightPositionNeedsReset[actuatorNumber]) {
		resetLightPosition()
	}

	// Control height of actuator panel and light ring

	// transform (-1 => 1) range into reasonable values for actuator movement (5.75 => 11)
	const boundedValue = 2.64 * roll + 8.39;

	// move point on light ring
	const x = closedSpline.points[actuatorNumber].x;
	const z = closedSpline.points[actuatorNumber].z;
	closedSpline.points[actuatorNumber].lerp(new THREE.Vector3(x, boundedValue, z), lerpSmoothness);

	// move panel on actuator
	updatePanelHeight[actuatorNumber] && updatePanelHeight[actuatorNumber](boundedValue - 7.1);
	
	// move camera Y in lockstep, if we like
	if (moveCameraYWithRollDataConfigs[actuatorNumber]) {
		const camX = camera.position.x;
		const camZ = camera.position.z;
		camera.position.lerp(new THREE.Vector3(camX, boundedValue, camZ), lerpSmoothness);
	}
};
const handleYaw = (yaw, actuatorNumber) => {
	if (dataControlsLightPosition) {
		const defaultY =  [
			-2.9 * 2,
			3.6 * 2,
			6.4 * 2,
			1.6 * 2,
			-4.3 * 2,
			-6.7 * 2
		]
		const boundedValue = yaw * 20 - 15 // transform into canvas-ish size range
		points[actuatorNumber].z = defaultY[actuatorNumber] + boundedValue
		lightPositionNeedsReset[actuatorNumber] = true
		return
	}
	if (lightPositionNeedsReset[actuatorNumber]) {
		resetLightPosition()
	}


	// transform (-1 => 1) range into normalised values for colors (0 => 1)
	const boundedValue = yaw * 0.5 + 0.5;
	colors[actuatorNumber].setRGB(colors[actuatorNumber].r, boundedValue, boundedValue);
};

const parseData = (dataA) => {
	let dataEntries = Object.entries(JSON.parse(dataA));
	for (let [address, message] of dataEntries) {
		const streamNumberMatch = address.match(/STREAM_(\d)/);
		const streamNumber = streamNumberMatch ? streamNumberMatch[1] : null;

		const isQuaternionData = address.match(/\/.*\/quaternion$/);
		if (!streamNumber || !message[1] || !isQuaternionData) {
			continue;
		}

		const actuatorNumber = streamNumber - 1;

		const [pitch, roll, yaw] = message;

		if (streamNumber === targetForConsoleOutput) {
			consoleContainer.innerHTML = `actuator: ${actuatorNames[streamNumber - 1]}\npitch: ${pitch}\nroll: ${roll}\nyaw: ${yaw}`
		}
		
		// handlePitch(pitch, actuatorNumber);
		// handleRoll(roll, actuatorNumber);
		// handleYaw(yaw, actuatorNumber);
	}
};

const ws = new WebSocket('wss://photon.friendred.studio:8080/ws');
ws.onmessage = (msg) => {
	const data = JSON.parse(msg.data);
	const messageTypesToIgnore = [
		"recordingsData",
		"recordingStatus",
		"playingStatus",
		"ioConfig"
	];

	if (messageTypesToIgnore.includes(data.type)) return;

	parseData(msg.data);
};

/**
 * Loaders
 */

const updateAllMaterials = () => {
	//provide a function to traverse function
	scene.traverse((child) => {
		//we only need mesh inside child, no need lights etc
		//so use js instance of
		if (
			child instanceof THREE.Mesh &&
			child.material instanceof THREE.MeshStandardMaterial
		) {
			child.material.needsUpdate = true;
			child.castShadow = true;
			child.receiveShadow = true;
		}
	});
};

//----------------------------------scene
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(
	75,
	window.innerWidth / window.innerHeight,
	0.1,
	1000
);

scene.add(camera);

//splines-------------------------------------------------

//curve

const center = new THREE.Vector3(0, 0, 0);
const positionOne = new THREE.Vector3(7.5 * 2, 8, -2.9 * 2);
const colorOne = new THREE.Color(0x0000ff)
const positionTwo = new THREE.Vector3(6.3 * 2, 8, 3.6 * 2);
const colorTwo = new THREE.Color(0x00ff00)
const positionThree = new THREE.Vector3(-0.3 * 2, 8, 6.4 * 2);
const colorThree = new THREE.Color(0xff0000)
const positionFour = new THREE.Vector3(-7.2 * 2, 8, 1.6 * 2);
const colorFour = new THREE.Color(0xffff00)
const positionFive = new THREE.Vector3(-4.9 * 2, 8, -4.3 * 2);
const colorFive = new THREE.Color(0x00ffff)
const positionSix = new THREE.Vector3(1.4 * 2, 8, -6.7 * 2);
const colorSix = new THREE.Color(0xff00ff)

var points = [
	positionOne,
	positionTwo,
	positionThree,
	positionFour,
	positionFive,
	positionSix
];

var colors = [
	colorOne,
	colorTwo,
	colorThree,
	colorFour,
	colorFive,
	colorSix,
]

var closedSpline = new THREE.CatmullRomCurve3(points);

closedSpline.curveType = "catmullrom";
closedSpline.closed = true;
closedSpline.needsUpdate = true;

const circleRadius = 0.6;
const shape = new THREE.Shape();
shape.moveTo( 0, circleRadius );
shape.quadraticCurveTo( circleRadius, circleRadius, circleRadius, 0 );
shape.quadraticCurveTo( circleRadius, -circleRadius, 0, -circleRadius );
shape.quadraticCurveTo( -circleRadius, -circleRadius, -circleRadius, 0 );
shape.quadraticCurveTo( -circleRadius, circleRadius, 0, circleRadius );

let extrudeSettings = {
	steps: 58,
	depth: 1,
	bevelEnabled: false,
	bevelThickness: 1,
	bevelSize: 1,
	bevelOffset: 0,
	bevelSegments: 1,
	extrudePath: closedSpline,
};

let extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

const extrudeMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });

const calculatedRGB = () => {
	const addedColors = colors.reduce((acc, color) => {
		const r = acc[0] + color.r
		const g = acc[1] + color.g
		const b = acc[2] + color.b
		return [r, g, b]
	}, [0,0,0])
	
	// snap range of 0-6 to 0.5-1
	const normalisedColorValues = addedColors.map(v => v * 0.166666)
	return normalisedColorValues
}

let extrudeMesh = new THREE.Mesh(extrudeGeometry, extrudeMaterial);
extrudeMesh.material.color.setRGB(...calculatedRGB())

scene.add(extrudeMesh);

//--------------------------------------------------------
/**
 * 3D Models: Linear Actuators with Mounted Panels
 */

 const actuatorModelConfigs = [
	{
		position: {
			x: closedSpline.points[0].x + 1.2,
			y: 0,
			z: closedSpline.points[0].z
		},
		rotation: {
			y: Math.PI * 1.5,
		}
	},
	{
		position: {
			x: closedSpline.points[1].x + 0.9,
			y: 0,
			z: closedSpline.points[1].z + 0.9
		},
		rotation: {
			y: Math.PI * 1.2,
		}
	},
	{
		position: {
			x: closedSpline.points[2].x,
			y: 0,
			z: closedSpline.points[2].z + 1.3
		},
		rotation: {
			y: Math.PI * 3,
		}
	},
	{
		position: {
			x: closedSpline.points[3].x - 1.3,
			y: 0,
			z: closedSpline.points[3].z
		},
		rotation: {
			y: Math.PI * 2.6,
		}
	},
	{
		position: {
			x: closedSpline.points[4].x - 0.9,
			y: 0,
			z: closedSpline.points[4].z - 0.9
		},
		rotation: {
			y: Math.PI * 2.22,
		}
	},
	{
		position: {
			x: closedSpline.points[5].x,
			y: 0,
			z: closedSpline.points[5].z - 1.3
		},
		rotation: {
			y: Math.PI * 1.9,
		}
	},
]

const actuatorPath = "models/linearActuator.glb";
const panelPath = "models/profile.glb";
const actuatorAndPanelScale = [6, 6, 6];

const updatePanelHeight = []

for (const actuatorModel of actuatorModelConfigs) {
	const { position: pos, rotation } = actuatorModel
	const loader = new GLTFLoader()
	loader.load(actuatorPath, (gltf) => {
		gltf.scene.scale.set(...actuatorAndPanelScale);
		gltf.scene.position.set(pos.x, pos.y, pos.z);
		gltf.scene.rotation.y = rotation.y;
		scene.add(gltf.scene);
		updateAllMaterials();
	});

	loader.load(panelPath, (gltf) => {
		gltf.scene.scale.set(...actuatorAndPanelScale);
		gltf.scene.position.set(pos.x, pos.y + 1, pos.z);
		gltf.scene.rotation.y = rotation.y;
		scene.add(gltf.scene);
		updateAllMaterials();
		updatePanelHeight.push((value) => {
			gltf.scene.position.lerp(new THREE.Vector3(pos.x, value, pos.z), lerpSmoothness);
		})
	});
}

const floor = new THREE.Mesh(
	new THREE.PlaneGeometry(360, 360),
	new THREE.MeshStandardMaterial({
		color: "#777777",
		roughness: 0.7,
		wireframe: false,
	})
);

floor.rotation.x = -Math.PI * 0.5;
floor.position.set(0, -0.5, 0);
scene.add(floor);


//-Lights--------------------------------------------------

//pointLight0
const pointLight = new THREE.PointLight("green", 0.5, 94, 6.12);
pointLight.position.set(5.5 * 2, 6, -1.9 * 2);
scene.add(pointLight);

//pointLight1
const pointLight1 = new THREE.PointLight(0x232fff, 0.5, 100);
pointLight1.position.set(3.3 * 2, 6, 2.0 * 2);
scene.add(pointLight1);

//pointLight2
const pointLight2 = new THREE.PointLight(0xa400ff, 0.5, 100);
pointLight2.position.set(-0.55 * 2, 6, 3.8 * 2);
scene.add(pointLight2);

//pointLight3
const pointLight3 = new THREE.PointLight(0xff0000, 0.5, 100);
pointLight3.position.set(-4.1 * 2, 6, 0.9 * 2);
scene.add(pointLight3);

//pointLight4
const pointLight4 = new THREE.PointLight(0xff4a, 0.5, 100);
pointLight4.position.set(-1.9 * 2, 6, -3.8 * 2);
scene.add(pointLight4);

//pointLight5
const pointLight5 = new THREE.PointLight(0xff1e, 0.5, 100);
pointLight5.position.set(3.4 * 2, 6, -4.2 * 2);
scene.add(pointLight5);

//endLights------------------------------------------------

//COMPOSER --------------------------------------------------

const params = {
	exposure: 1,
	bloomStrength: 0.52,
	bloomThreshold: 0,
	bloomRadius: 0,
};
const renderScene = new RenderPass(scene, camera);

const bloomPass = new UnrealBloomPass(
	new THREE.Vector2(window.innerWidth, window.innerHeight),
	1.5,
	0.4,
	0.85
);
bloomPass.threshold = params.bloomThreshold;
bloomPass.strength = params.bloomStrength;
bloomPass.radius = params.bloomRadius;

//------------------------------------------------------REnder
let renderer;
let moveCameraCallback = () => {};

const animate = () => {
	// prevent camera clipping through floor plane
	if (camera.position.y < 0.2) { camera.position.y = 0.2 }
	moveCameraCallback()

	requestAnimationFrame(animate);
	renderer.setClearColor("#000000");
	renderer.render(scene, camera);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	renderer.gammaFactor = 1.5;
	renderer.toneMapping = THREE.ReinhardToneMapping;
	extrudeMesh.material.color.setRGB(...calculatedRGB())
	
	//curve update
	closedSpline.needsUpdate = true;
	extrudeSettings.extrudePath.needsUpdate = true;

	//testing extrution change extrude setting?
	extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
	extrudeMesh.geometry.dispose();
	extrudeMesh.geometry = extrudeGeometry;


	//post processing

	// Update controls
	let composer = new EffectComposer(renderer);
	composer.addPass(renderScene);
	composer.addPass(bloomPass);
	composer.render();
};

const resize = () => {
	renderer.setSize(window.innerWidth, window.innerHeight);
	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
};

const scheduleCameraMoves = (orbitControls) => {
	const scheduledShots = cameraShots.reduce((acc, cameraShot, index) => {
		const targetMap = {
			center,
			positionOne,
			positionTwo,
			positionThree,
			positionFour,
			positionFive,
			positionSix
		}

		const shotInstructions = () => {
			
			consoleContainer.innerHTML = null
			if (cameraShot.showConsoleOutputForActuator) {
				targetForConsoleOutput = `${cameraShot.showConsoleOutputForActuator}`
				showConsoleContainer()
			} else {
				hideConsoleContainer()
				targetForConsoleOutput = null
			}

			camera.position.x = cameraShot.x || camera.position.x
			camera.position.y = cameraShot.y || camera.position.y
			camera.position.z = cameraShot.z || camera.position.z
			orbitControls.autoRotateSpeed = cameraShot.rotateSpeed || orbitControls.autoRotateSpeed
			for (const i in moveCameraYWithRollDataConfigs) moveCameraYWithRollDataConfigs[i] = Boolean(cameraShot.rollDataMovesCameraY?.includes(parseInt(i)))
			const newTarget = cameraShot.targetOffset 
				? new THREE.Vector3(targetMap[cameraShot.target].x + cameraShot.targetOffset.x, targetMap[cameraShot.target].y + cameraShot.targetOffset.y, targetMap[cameraShot.target].z + cameraShot.targetOffset.z).lerp(
						targetMap[cameraShot.target],
						lerpSmoothness
					)
				: targetMap[cameraShot.target]
			orbitControls.target = newTarget || orbitControls.target
		}
		const previousAccumulatedTime = index === 0 ? 0 : acc[index - 1][1]
		const accumulatedTimeMs = previousAccumulatedTime + cameraShot.timeInSecondsBeforeChangingToThisShot * 1000
		if (index === cameraShots.length - 1) console.log('Time in Seconds', accumulatedTimeMs / 1000)
		return [...acc, [shotInstructions, accumulatedTimeMs]]
	}, [])

	for (const [shotInstructions, shotTiming] of scheduledShots) setTimeout(shotInstructions, shotTiming)
}

export const createScene = (el) => {
	renderer = new THREE.WebGLRenderer({
		antialias: true,
		canvas: el,
		alpha: true,
	});

	// Camera Controls
	const orbitControls = new OrbitControls(camera, renderer.domElement);
	orbitControls.autoRotate = true;
	orbitControls.maxDistance = 75;
	orbitControls.enableDamping = true;
	scheduleCameraMoves(orbitControls)
	moveCameraCallback = () => { orbitControls.update() };

	resize();
	animate();
};

window.addEventListener("resize", resize);
