export const cameraShots = [
    {
        rotateSpeed: '0.4',
        target: 'center',
        timeInSecondsBeforeChangingToThisShot: 0,
        x: 1,
        y: 0.2,
        z: 75,
    },
    {
        timeInSecondsBeforeChangingToThisShot: 10,
        x: 12,
		y: 35,
		z: 12,
	},
    {
        rotateSpeed: '-0.4',
        timeInSecondsBeforeChangingToThisShot: 9.9,
    },
    {
        timeInSecondsBeforeChangingToThisShot: 0.1,
		x: 15,
		y: 1,
        z: 13,
	},
    {
		rotateSpeed: '0.2',
        showConsoleOutputForActuator: 1,
		target: 'positionOne',
        targetOffset: {
            x: -10,
            y: 0,
            z: 0,
        },
        timeInSecondsBeforeChangingToThisShot: 10,
		x: 12,
		y: 19,
		z: 7,
	},
    {
		rollDataMovesCameraY: [0],
        rotateSpeed: '0.15',
        showConsoleOutputForActuator: 1,
		target: 'positionOne',
        timeInSecondsBeforeChangingToThisShot: 10,
		x: 17,
		y: 7,
		z: -7,
	},
    {
		rollDataMovesCameraY: [1],
		rotateSpeed: '-0.15',
        showConsoleOutputForActuator: 2,
		target: 'positionTwo',
        timeInSecondsBeforeChangingToThisShot: 10,
		x: 13,
        y: 8,
		z: 9,
	},
    {
		rotateSpeed: '0',
        showConsoleOutputForActuator: 3,
		target: 'positionThree',
        targetOffset: {
            x: -10,
            y: 6,
            z: 5,
        },
        timeInSecondsBeforeChangingToThisShot: 10,
		x: 4,
		y: 3,
		z: 4,
	},
    {
		rotateSpeed: '0',
        showConsoleOutputForActuator: 4,
		target: 'positionFour',
        targetOffset: {
            x: -10,
            y: -4,
            z: 5,
        },
        timeInSecondsBeforeChangingToThisShot: 10,
		x: 2,
		y: 6,
		z: -10,
	},
    {
		rollDataMovesCameraY: [4],
		rotateSpeed: '0',
        showConsoleOutputForActuator: 5,
		target: 'positionFive',
        timeInSecondsBeforeChangingToThisShot: 10,
		x: -6,
		y: 7,
		z: -6,
	},
    {
        rollDataMovesCameraY: [5],
        rotateSpeed: '0.1',
        showConsoleOutputForActuator: 6,
		target: 'positionSix',
        targetOffset: {
            x: -10,
            y: 0,
            z: 10,
        },
        timeInSecondsBeforeChangingToThisShot: 10,
		x: 21,
        y: 7,
		z: -13,
	},
    {
		rotateSpeed: '-0.2',
        showConsoleOutputForActuator: 1,
		target: 'positionOne',
        targetOffset: {
            x: -2,
            y: -4,
            z: 2,
        },
        timeInSecondsBeforeChangingToThisShot: 10,
		x: 21,
		y: 1,
		z: -3,
	},
    {
        rotateSpeed: '0.05',
		target: 'center',
        timeInSecondsBeforeChangingToThisShot: 10,
        x: 6,
		y: 17,
		z: 12,
	},
    {
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 1,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 2,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 3,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 4,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 5,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        showConsoleOutputForActuator: 6,
        timeInSecondsBeforeChangingToThisShot: 10,
	},
    {
        timeInSecondsBeforeChangingToThisShot: 10,
	},
]